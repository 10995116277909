<template>
  <div class="flex min-h-screen flex-col pt-16 lg:pt-28 xl:pt-24">
    <NuxtLoadingIndicator color="#D92465" />
    <AppHeader />

    <section class="relative flex-1">
      <slot />
    </section>
    <AppFooter />
  </div>
</template>
